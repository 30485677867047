import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

const ThingsIndex = () => {
  return (
    <Layout>
      <SEO title="Things" />
      <h1>Things</h1>
      <ul>
        <li>
          <h3 style={{ marginTop: 0, marginBottom: '0.25em' }}><Link to="./deplagiarizer">Deplagiarizer</Link></h3>
          <p>Automatically cite your papers</p>
        </li>
        <li>
          <h3 style={{marginBottom: '0.25em'}}><a href="https://mordle.aaronolsen.ca">Mordle</a></h3>
          <p>Ever wanted more Wordle?</p>
        </li>
        <li>
          <h3 style={{marginBottom: '0.25em'}}><a href="https://rps.aaronolsen.ca">Rock, Paper, Scissors Arena</a></h3>
          <p>Watch rocks, papers, and scissors battle it out on your screen</p>
        </li>
      </ul>
    </Layout>
  )
}

export default ThingsIndex
